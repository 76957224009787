.toast-body {
  display: flex;
}

.toast {
  .gg-check-o {
    color: $success;
  }

  // animation-name: toastRaiseUp, toastRaiseDown;
  // animation-duration: .25s, .25s;
  // animation-delay: 0s, 2s;
  // animation-fill-mode: forwards;
  // transition: all .25s cubic-bezier(0, 0, .2, 1);
  // display: unset;

  // @keyframes toastRaiseUp {
  //   from {
  //     opacity: 0;
  //     transform: translateX(-50%) translateY(100%);
  //   }

  //   to {
  //     opacity: 1;
  //     transform: translateX(-50%) translateY(0%);
  //   }
  // }

  // @keyframes toastRaiseDown {
  //   from {
  //     opacity: 1;
  //   }

  //   to {
  //     opacity: 0;
  //   }
  // }
}
