.nav-tabs {
  border-bottom: $border-width solid $border-color;
  .nav-link {
    .active {
      // border-bottom: 3px solid $primary;

      padding-top: 0;
      padding-bottom: rem(12);
      margin-bottom: -2px;
      border-bottom: 2px solid color('light-gray');

      &:not(.active) {
        color: $dark;
      }

      &.active {
        border-color: color('primary');
      }
    }
  }
}