$sidebar-width: rem(375) !default;
$sidebar-box-shadow: 0 0.5rem 1rem rgba(51, 51, 51, 0.15) !default;
$sidebar-index: 800 !default;
$sidebar-bg: $gray-100 !default;

$sidebar-padding-x: 1rem !default;
$sidebar-padding-y: .5rem !default;

.sidebar {
  background-color: $sidebar-bg;
  box-shadow: $sidebar-box-shadow;
  width: $sidebar-width;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: $sidebar-index;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: $border-radius-lg;
  transition: $transition-base;
  height: auto;


  .btn-group-wrap {
    width: 100%;
  }

  .sidebar-header {
    padding: 1rem;

    .menu-wrap {
      position: absolute;
      right: -5.5rem;
      background-color: $white;
      padding: .5rem;
      border-radius: 4px;
      top: 0;
    }
  }

  .sidebar-body {
    overflow-y: auto;
  }

  .nav-item {
    cursor: pointer;

    &.active {
      background-color: color('light-primary');

      .nav-link {
        color: color('primary');
        font-weight: $font-weight-bold;
      }
    }

    &:hover {
      .nav-link {
        color: color('primary');
      }
    }
  }

  .nav-link {
    color: $body-color;
    line-height: 1;
    padding: 1rem $sidebar-padding-x;
  }

  .nav-item-group-title {
    user-select: none;
    padding: 1.5rem 1.5rem .5rem;
  }

  .card {
    margin: $sidebar-padding-y $sidebar-padding-x;
    box-shadow: $box-shadow-sm;
  }

}

@include media-breakpoint-down(sm) {
  #map {
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
      margin-top: .5rem;
      margin-right: .5rem;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .mapboxgl-ctrl-geocoder--input {
      height: 54px;
    }

    .mapboxgl-ctrl-geocoder--icon-search,
    .mapboxgl-ctrl-geocoder--button {
      top: 16px;
    }

    .mapboxgl-ctrl-geocoder--icon-close {
      margin-top: 0;
    }

    .mapboxgl-ctrl-top-right {
      width: calc(100% - 70px - .5rem);
    }

    .menu-close {
      margin-left: .5rem;
    }

    .menu-open,
    .menu-close {
      height: 38px;
      padding-left: 1rem;
      padding-right: 1rem;
      border: $border-width solid $primary;
      border-radius: 4px;
      color: $primary;
      align-items: center;
    }

    .menu-wrap {
      position: absolute;
      background-color: $white;
      padding: .5rem;
      border-radius: 4px;
      top: .5rem;
      left: .5rem;
      z-index: 3;
    }
    .sidebar {
      margin: 0;
      width: 100%;
      &:not(.active) {
        transform: translateX(calc(-100% - 1rem));
      }
    }

    .sidebar.sidebar-sm {
      position: unset;
      transform: none;
    }
  }
}
