#map {
  width: 100vw;
  height: 100vh;
}

.center-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  // margin: 1rem;
  margin-bottom: 1rem;
}

.center-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 1rem;
}

@include media-breakpoint-down(sm) {
  .center-top-sm {
    top: 120px;
    bottom: unset;
    margin-bottom: unset;
    width: fit-content;
  }
}

.right-bottom {
  position: absolute;
  bottom: .1rem;
  right: 1rem;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  a {
    color: $blue;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.2);
    padding: .2rem .5rem;
    border-radius: 50px;
  }
  span {
    opacity: .5;
  }
}

.modal-new-shop .col-form-label {
  @include media-breakpoint-up(sm) {
    text-align: right;
  }
}

.shop-marker {
  display: flex;
  border-radius: 1rem;
  padding: .5rem;
  color: transparent;
  line-height: 1rem;
  font-size: 1rem;
  white-space: nowrap;
  cursor: pointer;

  @include media-breakpoint-up(sm) {
    &:hover {
      background: rgba(0, 0, 0, 0.8);
      color: #fff;

      span {
        display: inline-block;
      }
    }
  }

  &:before {
    content: ' ';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: red;
    border-radius: 1rem;
    margin-right: .5rem;
  }

  span {
    display: none;
  }
}

.custom-file-wrapper {
  width: 105px !important;
  min-height: 90px;
}

.custom-file {
  border: 1px dashed var(--gray);
  border-radius: 2px;
}

.custom-file .col-form-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.custom-file.has-image img {
  width: 100%;
}

.custom-file.has-image>*:not(img) {
  display: none;
}

a:hover,
.custom-file-input {
  cursor: pointer;
}

#new-products-info .form-group.row:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 1rem;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group {
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
}

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
  display: none;
}
