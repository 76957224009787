.gg-check-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px
}

.gg-check-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg)
}

.gg-menu {
  transform: scale(var(--ggs, 1))
}

.gg-menu,
.gg-menu::after,
.gg-menu::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor
}

.gg-menu::after,
.gg-menu::before {
  content: "";
  position: absolute;
  top: -6px
}

.gg-menu::after {
  top: 6px
}

.gg-close {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 40px
}

.gg-close::after,
.gg-close::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px
}

.gg-close::after {
  transform: rotate(-45deg)
}

.icon-verify {
  svg {
    width: 1.25rem;
  }
}

.icon-gps {
  height: 38px;
  width: 44px;
  border: 1px solid $border-color;
  border-radius: 4px;
  color: #FF6F00;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .5rem;

  svg {
    width: 1.25rem;
  }
}


@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}
