.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 999;
  // overflow: hidden;
  max-height: 100%;;

  .nav-tabs {
    justify-content: center;
    height: 3rem;

    .nav-item {
      display: flex;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      &.item-hambuger {
        max-width: 5rem;
      }
    }

    .nav-link {
      display: flex;
      align-items: center;
      border-radius: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      justify-content: center;

      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .sidebar-header {
    z-index: 10;
  }
  .sidebar-body {
    margin-top: 101px;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $gray-100;
    z-index: 9;
    &.no-filter {
      margin-top: 0;
    }
  }
}
