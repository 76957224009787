.badge-primary {
  color: $primary;
  background-color: rgba(255,111,0,0.1)
}

.badge-primary-secondary {
  color: $primary-secondary;
  background-color: rgba(253,179,44,0.1);
}

.badge-primary-third {
  color: $primary-third;
  background-color: rgba(53,196,166,0.1);
}

.badge-primary-fourth {
  color: $primary-fourth;
  background-color: rgba(38,131,255,0.1)
}

.badge-secondary {
  color: $secondary;
  background-color: rgba(173,181,189,0.1);
}